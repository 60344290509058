import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'input'
  ]
  change(event) {
    const input = document.getElementById('changeable-input');

    if(event.currentTarget.value == 'false') {
      var formField = "<div class='input string optional product_bundle_regex'><label class='string optional' for='product_bundle_regex'>Regex</label><input class='string optional' type='text' name='product_bundle[regex]' id='product_bundle_regex'></div>"
      input.innerHTML = formField;
    } else {
      var formField =  "<div class='input integer optional product_bundle_quantity'><label class='integer optional' for='product_bundle_quantity'>Quantity</label><input class='numeric integer optional' type='number' step='1' name='product_bundle[quantity]' id='product_bundle_quantity'></div>"
      input.innerHTML = formField;
    }
  }
}
