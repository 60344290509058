<template>
  <div class="modal fade" role="dialog" aria-labelledby="formModal" aria-hidden="true" :id="modalId">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTitle">{{ modalTitle }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click.prevent="submitForm">{{ submitBtnText }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Modal",
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    modalId: {
      type: String,
      required: true
    },
    submitBtnText: {
      type: String,
      default: "Submit"
    }
  },
  methods: {
    submitForm(){
      this.$emit('form-submit')
    }
  }
}
</script>