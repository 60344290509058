<template>
  <div>
    <div class="row mb-lg">
      <div class="col-md-3 d-flex">
        <span class="span-center">Show</span>
        <select class="form-control form-control-sm small-select" v-model="perPage" @change="handleEntriesChange($event, fetchInvoicesExports)">
          <option v-for="num in recordsPerPage" :key="num" :value="num">{{ num }}</option>
        </select>
        <span class="span-center">entries</span>
      </div>
      <div class="col-md-5"></div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-8">
           </div>
          <div class="col-md-4" v-if="!this.current_user_read_only">
            <button type="button" class="btn btn-primary pull-right" data-toggle="modal" data-target="#add-import-modal">
              New Invoice Export
            </button>
          </div>
        </div>
      </div>
    </div>

    <table class="table dtCustom" id="invoices-exports">
      <thead>
        <tr>
          <th scope="col" :class="{ sorting: col.sortable }" v-for="(col,index) in tableColumns" @click="sortBy(index, fetchInvoicesExports)">{{ col.name }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="invoicesExports.length > 0">
          <template v-for="(invoiceExport, index) in invoicesExports">
            <invoice-export-table-row :index="index" :invoice-export="invoiceExport" :sort-col="sortCol" @open-drawer="openDrawer" :key="invoiceExport.id" />
            <invoice-export-info :invoice-export="invoiceExport" :key="invoiceExport.id" />
          </template>
        </template>
        <tr v-else>
          <td colspan="5" class="text-center p-4">No Records Found</td>
        </tr>
      </tbody>
    </table>
    <div class="row mt-4">
      <div class="col-3 p-3">
        <p>{{ positionText }}</p>
      </div>
      <div class="col-9">
        <v-pagination v-model="currentPage" :page-count="totalPages" :classes="bootstrapClasses"
                      :labels="paginationAnchorTexts"
                      v-on:input="handlePageChange($event, fetchInvoicesExports)">

        </v-pagination>
      </div>
    </div>
    <modal modal-title="New Invoice Export" modal-id="add-import-modal" v-if="!this.current_user_read_only">
      <invoice-export-form/>
    </modal>
  </div>
</template>

<script>
import InvoiceExportForm from '../components/invoices_exports/InvoiceExportForm';
import InvoiceExportInfo from '../components/invoices_exports/InvoiceExportInfo';
import Modal from '../components/Modal'
import vPagination from 'vue-plain-pagination'
import paginationMixin from "../mixins/paginationMixin";
import currentUserMixin from "../mixins/currentUserMixin";
import InvoiceExportTableRow from "../components/invoices_exports/InvoiceExportTableRow";
import axios from "axios";

export default {
  name: "InvoicesExports",
  components: {
    InvoiceExportForm,
    InvoiceExportInfo,
    Modal,
    vPagination,
    InvoiceExportTableRow
  },
  mixins: [ paginationMixin, currentUserMixin ],
  data: function () {
    return {
      tableColumns: [
        { name: "ID",sortable: false },
        { name: "Added on",sortable: false },
        { name: "From Date",sortable: false },
        { name: "To Date",sortable: false },
        { name: "Category",sortable: false },
        { name: "Status", sortable: false },
        { name: "Download", sortable: false },
      ],
      perPage: 50,
      sortCol: 1,
      tableId: 'table#invoices-exports',
      drawerStatus: { id: 0, open: false },
      invoicesExports: []
    }
  },
  methods: {
    openDrawer(id){
      document.getElementById(id).classList.toggle('hidden');
    },
    fetchInvoicesExports(){
      const params = this.getRequestParams({
        searchTerm: this.searchTerm,
        page: this.currentPage,
        perPage: this.perPage,
        sortCol: this.sortCol,
        sortDir: this.sortDir,
      });

      axios.get("/invoices_exports/exports.json", { params }).then(response => {
        let data = response.data;
        this.invoicesExports = data.data
        this.currentPage  = data.current_page;
        this.totalPages   = data.total_pages;
        this.totalEntries = data.total_entries;
      }).catch(error => {
        let response = error.response;
        alert(`Error Code: ${response.status} => ${response.statusText}`)
      });
    },
  },
  created() {
    this.fetchInvoicesExports();
  },
}
</script>
