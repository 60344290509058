<template>
  <div>
    <div class="row mb-lg">
      <div class="col-md-3 d-flex">
        <span class="span-center">Show</span>
        <select class="form-control form-control-sm small-select" v-model="perPage" @change="handleEntriesChange($event, fetchOrderSamples)">
          <option v-for="num in recordsPerPage" :key="num" :value="num">{{ num }}</option>
        </select>
        <span class="span-center">entries</span>
      </div>
      <div class="col-md-5"></div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="Search..." v-model="searchTerm" v-on:input="performSearch(fetchOrderSamples)" />
          </div>
          <div class="col-md-4" v-if="!this.current_user_read_only">
            <button type="button" class="btn btn-primary pull-right" data-toggle="modal" data-target="#add-sample-modal">
              New Sample
            </button>
          </div>
        </div>
      </div>
    </div>

    <table class="table dtCustom" id="order-samples">
      <thead>
        <tr>
          <th scope="col" :class="{ sorting: col.sortable }" v-for="(col,index) in tableColumns" @click="sortBy(index, fetchOrderSamples)">{{ col.name }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="orderSamples.length > 0">
          <template v-for="(orderSample, index) in orderSamples">
            <order-sample-table-row :index="index" :order-sample="orderSample" :sort-col="sortCol" @open-drawer="openDrawer" :key="orderSample.id" />
            <order-sample-info :order-sample="orderSample" @picture-added="updateSample" :current_user_read_only="current_user_read_only" :key="orderSample.id" />
          </template>
        </template>
        <tr v-else>
          <td colspan="5" class="text-center p-4">No Records Found</td>
        </tr>
      </tbody>
    </table>
    <div class="row mt-4">
      <div class="col-3 p-3">
        <p>{{ positionText }}</p>
      </div>
      <div class="col-9">
        <v-pagination v-model="currentPage" :page-count="totalPages" :classes="bootstrapClasses"
                      :labels="paginationAnchorTexts"
                      v-on:input="handlePageChange($event, fetchOrderSamples)">

        </v-pagination>
      </div>
    </div>
    <modal modal-title="New Sample Order" modal-id="add-sample-modal" v-if="!this.current_user_read_only">
      <order-sample-form/>
    </modal>
  </div>
</template>

<script>
import DataExportForm from '../../components/data/DataExportForm'
import Modal from '../../components/Modal'
import vPagination from 'vue-plain-pagination'
import paginationMixin from "../../mixins/paginationMixin";
import currentUserMixin from "../../mixins/currentUserMixin";
import DataExportTableRow from "../../components/data/DataExportTableRow";
import axios from "axios";

export default {
  name: "DataExports",
  components: {
    DataExportForm,
    Modal,
    vPagination,
    DataExportTableRow
  },
  mixins: [ paginationMixin, currentUserMixin ],
  data: function () {
    return {
      tableColumns: [
        { name: "Shop",sortable: true },
        { name: "Date",sortable: true },
        { name: "Order",sortable: true },
        { name: "Influencer", sortable: true },
        { name: "Notes", sortable: true },
        { name: "Details",sortable: false }
      ],
      perPage: 10,
      sortCol: 1,
      tableId: 'table#order-samples',
      drawerStatus: { id: 0, open: false },
      orderSamples: []
    }
  },
  methods: {
    openDrawer(id){
      document.getElementById(id).classList.toggle('hidden');
    },
    updateSample(payload){
      this.orderSamples = this.orderSamples.map(sample => sample.id === payload.id ? payload : sample);
    },
    addSample(sample){
      $("#add-sample-modal").modal('hide');
      this.orderSamples.unshift(sample);
    },
    fetchOrderSamples(){
      const params = this.getRequestParams({
        searchTerm: this.searchTerm,
        page: this.currentPage,
        perPage: this.perPage,
        sortCol: this.sortCol,
        sortDir: this.sortDir,
      });

      axios.get("/orders/samples.json", { params }).then(response => {
        let data = response.data;

        this.orderSamples = data.data
        this.currentPage  = data.current_page;
        this.totalPages   = data.total_pages;
        this.totalEntries = data.total_entries;
      }).catch(error => {
        let response = error.response;
        alert(`Error Code: ${response.status} => ${response.statusText}`)
      });
    },
  },
  created() {
    this.fetchOrderSamples();
  },
}
</script>
