<template>
  <div>
    <div class="row mb-lg">
      <div class="col-md-3 d-flex">
        <span class="span-center">Show</span>
        <select class="form-control form-control-sm small-select" v-model="perPage" @change="handleEntriesChange($event, fetchCarriers)">
          <option v-for="num in recordsPerPage" :key="num" :value="num">{{ num }}</option>
        </select>
        <span class="span-center">entries</span>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="Search..." v-model="searchTerm" v-on:input="performSearch(fetchCarriers)" />
          </div>
        </div>
      </div>
    </div>

    <table class="table dtCustom" id="carriers" style="width: 100%;">
      <thead>
      <tr>
        <th scope="col" :class="{ sorting: false, 'd-none': false }">Name</th>
        <th scope="col" :class="{ sorting: false, 'd-none': false }">Code</th>
        <th scope="col" :class="{ sorting: false, 'd-none': false }">Country</th>
      </tr>
      </thead>
      <tbody>
        <tr v-if="carriers.length > 0"
          v-for="(carrier, index) in carriers"
          :carrier="carrier" :index="index" :key="carrier.id" :sort-col="sortCol">
          <td>{{ carrier.name }}</td>
          <td>{{ carrier.code }}</td>
          <td>{{ carrier.country }}</td>
        </tr>
        <tr v-else>
          <td colspan="7" class="text-center p-4">No carriers found</td>
        </tr>
      </tbody>
    </table>
    <div class="row mt-4">
      <div class="col-3 p-3">
        <p>{{ positionText }}</p>
      </div>
      <div class="col-9">
        <v-pagination v-model="currentPage" :page-count="totalPages" :classes="bootstrapClasses" :labels="paginationAnchorTexts" v-on:input="handlePageChange($event, fetchCarriers)"></v-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import paginationMixin from "../mixins/paginationMixin";
import vPagination from 'vue-plain-pagination'
import axios from "axios";

export default {
  name: "Carriers",
  components: { vPagination },
  mixins: [ paginationMixin ],
  data: function(){
    return {
      tableColumns: [
        { name: "Name",sortable: false },
        { name: "Code",sortable: false }
      ],
      tableId: 'table#carriers',
      carriers: [],
    }
  },
  methods: {
    fetchCarriers(){
      const params = this.getRequestParams({
        searchTerm: this.searchTerm,
        page: this.currentPage,
        perPage: this.perPage,
        sortCol: this.sortCol,
        sortDir: this.sortDir,
      });

      axios.get("/carriers.json", { params }).then(response => {
        let data = response.data;

        this.carriers  = data.data
        this.currentPage  = data.current_page;
        this.totalPages   = data.total_pages;
        this.totalEntries = data.total_entries;
      }).catch(error => {
        let response = error.response;
        alert(`Error Code: ${response.status} => ${response.statusText}`)
      });
    },
  },
  created() {
    this.fetchCarriers();
  },
}
</script>