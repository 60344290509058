<template>
  <tr :class="classObject" :key="orderSample.id">
    <td :class="tdClass(0)">{{ orderSample.shop.name }}</td>
    <td :class="tdClass(1)">{{ orderSample.created_at }}</td>
    <td :class="tdClass(2)"><a :href="orderSample.order.link" target="_blank">Sample#{{ orderSample.id }}</a> </td>
    <td :class="tdClass(3)">{{ orderSample.influencer.name }} <a :href="linkTo('instagram', orderSample.influencer.instagram)" target="_blank" v-if="orderSample.influencer.instagram" style="float: right;"><i class="fa fa-instagram"></i></a></td>
    <td :class="tdClass(4)">{{ orderSample.notes || '-' }}</td>
    <th scope="col" style="width: 45px;" class="text-center">
      <a href="#" @click.prevent="toggleDrawer(orderSample.id)" title="Expand/Collapse" style="color:#2b2b2b;">
        <i class="fa fa-minus-circle text-danger" v-if="drawerOpen"></i>
        <i class="fa fa-plus-circle" v-else></i>
      </a>
    </th>
  </tr>
</template>
<script>
export default {
  name: "TableRow",
  props: {
    orderSample: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    sortCol: {
      type: Number,
    }
  },
  data(){
    return {
      drawerOpen: false,
    }
  },
  methods: {
    toggleDrawer(id){
      this.drawerOpen = !this.drawerOpen;
      this.$emit('open-drawer',id);
      $('[data-toggle="tooltip"]').tooltip();
    },
    linkTo(service, username){
      if(service === "snapchat") return `https://www.snapchat.com/add/${username}`
      if(service === "instagram") return `https://www.instagram.com/${username}`
      if(service === "facebook") return `https://www.facebook.com/${username}`
    },
    tdClass(id){
      return {
        sorting_1: this.sortCol === id
      }
    }
  },
  computed: {
    classObject(){
      return {
        even: this.index % 2 === 0,
        odd: this.index % 2 !== 0
      }
    }
  }
}
</script>