<template>
  <form action="/data/import" method="post" enctype="multipart/form-data" ref="dataImportForm" id="dataImportForm">
    <div class="alert alert-danger" role="alert" v-if="errors.length">
      <ul>
        <li v-for="error in errors">{{ error }}</li>
      </ul>
    </div>
    <fieldset v-for="(item, index) in import_data.data">
      <legend>Data:</legend>
      <div>
        <div class="form-group col-md-8">
          <label>Category</label>
          <select name="data[][category]" class="form-control" v-model="item.category" required>
            <option value="" disabled selected>Choose Category</option>
            <option v-for="category in dataImportCategories" :value="category.id" :key="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-8">
          <label>Sub Category</label>
          <select name="data[][sub_category]" class="form-control" v-model="item.sub_category" required>
            <option value="" disabled selected>Choose Sub Category</option>
            <option v-for="category in dataImportSubCategories" :value="category.id" :key="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-1">
          <label for="file">File</label>
          <input name="data[][file]" type="file" accept=".xls,.xlsx,.csv" required>
        </div>
      </div>
      <div class="form-group col-md-2 pt-lg">
        <a href="#" class="text-danger pull-right" @click.prevent="removeDataImportItem(index, item.id)" v-if="import_data.data.length > 1"><i class="fa fa-times"></i>Remove</a>
      </div>
    </fieldset>
    <div class="form-group">
      <a href="#" @click.prevent="addDataImportItem"><i class="fa fa-plus"></i> Add Another Data Import</a>
    </div>

  </form>
</template>
<script>
import axios from "axios";

export default {
  name: "DataImportForm",
  data: function() {
    return {
      import_data: {
        data: [{}]
      },
      data: {},
      errors: [],
      dataImportSubCategories: [
        {id: 'yc_invoice', name: 'YC Invoice data'},
        {id: 'xh_invoice', name: 'XH Invoice data'},
        {id: 'david_invoice', name: 'David Invoice and fees data'},
        {id: 'yc_vat', name: 'YC VAT and Tax data'},
        {id: 'xh_vat', name: 'XH VAT and Tax data'},
      ],
      dataImportCategories: [{id: "invoices", name: "Invoices"}],
    }
  },
  methods: {
    resetForm(){
      this.import_data = {data: [{}]}
    },
    processForm(){
      $("#dataImportForm").submit();
      // const headers = {'Accept': 'application/json'}

      // const params = this.import_data;
      // axios.post('/data/import', params, { headers: headers}).then(response => {
      //   console.log("RESPONSE")
      //   console.log(response)
      //   console.log("RELOAD HERE")
      //   this.$refs.dataImportForm.reset();
      //   this.errors = [];
      //   this.resetForm();
      //   this.$emit('influencer-added', response.data);
      // }).catch(error => {
      //   this.errors = error.response.data.errors;
      //   $('#add-influencer-modal').animate({ scrollTop: 0 }, 'slow');
      // })
    },
    addDataImportItem() {
      this.import_data.data.push(Object.assign({}, this.data));
    },
    removeDataImportItem(index, id) {
      this.$delete(this.import_data.data, index);
    },
    processFile(event, index){
      let file = event.target.files[0]
      this.import_data.data[index].file = {
        file_name: file.name,
      }
      let reader = new FileReaderSync();
      this.import_data.data[index].file['binary'] = reader.readAsBinaryString(file)
    },
  },
  watch: {
    populateWith: {
      deep: true,
      handler(){
        this.resetForm();
        this.errors = [];
      }
    }
  },
  created() {
    this.$parent.$on('form-submit', this.processForm);
  }
}
</script>
