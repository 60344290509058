<template>
  <tr class="bg-lighter hidden" :id="orderSample.id">
    <td colspan="6">
      <div class="row">
        <div :class="{'col-md-8': !orderSample.picture_url,'col-md-9': orderSample.picture_url !== null}">
          <div v-for="item in orderSample.order_items">
            <div class="d-flex flex-row">
              <div class="p-2 flex-even">
                <h5>Product</h5>
                <p class="mb-2 text-truncate" :title="item.product" data-toggle="tooltip">{{ item.product }}</p>
                <p class="font-italic">({{ item.supplier }})</p>
              </div>
              <div class="p-2 flex-even">
                <h5>Tracking Code</h5>
                <p class="mb-2" v-if="item.tracking_code == null">N/A</p>
                <p class="mb-2" else>{{ item.tracking_code }}</p>
                <span class="badge badge-primary" v-if="item.tracking_status !== null">{{ item.tracking_status }}</span>
              </div>
              <div class="p-2 flex-even">
                <h5>Extra Info</h5>
                <div v-if="item.necklace_name !== null">
                  <p v-for="i in item.necklace_name.split(',')" class="mb-2">{{ i }}</p>
                </div>
                <p v-else>N/A</p>
              </div>
              <div class="p-2 flex-even">
                <h5>Logistics Status</h5>
                <p>{{ item.logistics_status }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" v-if="orderSample.picture_url">
          <div class="card mb-3 float-right">
            <a :href="orderSample.picture_url" target="_blank">
              <img :src="orderSample.picture_url" class="card-img-top" alt="" style="max-height: 200px;object-fit: cover;" />
            </a>
            <div class="card-body">
              <p class="card-text text-center"><small class="text-muted">Added On: {{ orderSample.updated_at }}</small></p>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-else>
          <vue-dropzone ref="myVueDropzone" id="dropzone"
                        :options="dropzoneOptions"
                        :useCustomSlot="true"
                        v-on:vdropzone-error="submitError"
                        v-on:vdropzone-success="submitSuccess"
                        v-on:vdropzone-sending="sendingEvent"
                        v-on:vdropzone-upload-progress="showProgress"
                        v-if="!current_user_read_only">
            <div class="dropzone-content">
              <i class="fa fa-picture-o"></i>
            </div>
            <em>Drop image here or click to upload.</em>
          </vue-dropzone>
          <div class="progress mt-lg" v-if="!current_user_read_only">
            <div class="progress-bar bg-success d-none" :id="progressBarId" role="progressbar">{{ uploadProgress }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <a v-if="orderSample.order_items.map(item => (item.logistics_status)).filter(n => n == 'processed').length == 0 && !current_user_read_only" data-confirm="Are you sure you want to delete this sample order? This action is irreversible." class="text-danger float-right" rel="nofollow" data-method="delete" :href="`/orders/samples/${orderSample.id}`" title="You can delete this order as long as it's not processed" data-toggle="tooltip">
            <i class='fa fa-trash'></i>
          </a>
          <a style="cursor: not-allowed;" v-if="orderSample.order_items.map(item => (item.logistics_status)).filter(n => n == 'processed').length > 0 && !current_user_read_only" title="Orders with processed items cannot be deleted" data-toggle="tooltip" class="float-right">
            <i class='fa fa-trash'></i>
          </a>
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
export default {
  name: "ExtraInfo",
  components: { vueDropzone: vue2Dropzone},
  props: {
    orderSample: {
      type: Object,
      required: true
    },
    current_user_read_only: {
      type: Boolean
    }
  },
  data(){
    return {
      uploadProgress: "2%",
      progressBarId: `prog-${this.orderSample.id}`
    }
  },
  methods: {
    sendingEvent(file, xhr, formData) {
      // formData.append('listing[name]', this.listing.name);
      // add data to request
    },
    submitSuccess(file, response) {
      this.$emit('picture-added', response);
    },
    submitError(file, message, xhr) {
      console.log(message.response.data)
    },
    showProgress(file, progress, bytesSent){
      this.uploadProgress = `${progress.toFixed()}%`;
      const progressBar =  document.getElementById(this.progressBarId);
      progressBar.classList.remove('d-none');
      progressBar.style.setProperty('width', this.uploadProgress);
    }
  },
  computed: {
    dropzoneOptions(){
      return {
        url: `/orders/samples/${this.orderSample.id}`,
        method: 'PATCH',
        thumbnailWidth: 150,
        paramName: 'image',
        acceptedFiles: 'image/*',
        maxFiles: 1,
        addRemoveLinks: true,
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      }
    }
  },
  mounted() {
    this.$nextTick(()=>{
    });
  }
}
</script>