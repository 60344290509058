<template>
  <tr class="bg-lighter hidden" :id="invoiceExport.id" :key="invoiceExport.id">
    <td colspan="12">
      <div class="row">
        <div class="col-md-12">
          <p v-for="property in Object.keys(invoiceExport.extra_info)" v-if="property != 'Logs'">{{property}}: {{invoiceExport.extra_info[property]}}</p>
        </div>
      </div>
      <div class="row">
        <div v-if="invoiceExport.extra_info['Logs']" class="col-md-12">
          <ul>
          <li v-for="log in invoiceExport.extra_info['Logs'].slice().sort((a,b) => (a.substr(0,9) == '[SUCCESS]') ? 1 : -1)"><span :style="'background-color: ' + log_color(log)">{{log}}</span></li>
          </ul>
        </div>
      </div>
      <div class="row">
      </div>
    </td>
  </tr>
</template>
<script>

export default {
  name: "invoiceExportInfo",
  props: {
    invoiceExport: {
      type: Object,
      required: true
    },
    current_user_read_only: {
      type: Boolean
    }
  },
  data(){
    return {}
  },
  methods: {
    log_color(log) {
      if (log.substr(0,9) == '[SUCCESS]') {
        return "#00ff40"
      } else if (log.substr(0,9) == '[SKIPPED]') {
        return "#fff700"
      } else {
        return "#ff2200"
      }
    }
  },
  mounted() {
  }
}
</script>
