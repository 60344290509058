import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import Carriers from '../views/Carriers'
Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  const container = document.getElementById("carriers");
  if(!container) return;

  const app = new Vue({
    el: '#carriers',
    components: { Carriers }
  })
})
