import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import DataExports from '../views/data/DataExports'
import DataImports from "../views/data/DataImports"

// import 'vue2-dropzone/dist/vue2Dropzone.min.css'

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  const container = document.getElementById("data-dashboard");
  if(!container) return;

  const app = new Vue({
    el: '#data-dashboard',
    components: { DataImports, DataExports }
  })
})
