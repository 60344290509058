<template>
  <tr :class="classObject">
    <td :class="tdClass(1)">{{ invoiceExport.id }}</td>
    <td :class="tdClass(1)">{{ invoiceExport.created_at }}</td>
    <td :class="tdClass(1)">{{ invoiceExport.from_date }}</td>
    <td :class="tdClass(1)">{{ invoiceExport.to_date }}</td>
    <td :class="tdClass(1)">{{ invoiceExport.sub_category }}</td>
    <td :class="tdClass(1)">{{ invoiceExport.status }}</td>
    <td :class="tdClass(1)"><a :href="invoiceExport.file_url" target="_blank">{{ invoiceExport.file_name}}</a></td>
    <th scope="col" style="width: 45px;" class="text-center">
      <a href="#" @click.prevent="toggleDrawer(invoiceExport.id)" title="Expand/Collapse" style="color:#2b2b2b;">
        <i class="fa fa-minus-circle text-danger" v-if="drawerOpen"></i>
        <i class="fa fa-plus-circle" v-else></i>
      </a>
    </th>
  </tr>
</template>
<script>
export default {
  name: "TableRow",
  props: {
    invoiceExport: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true,
    },
    sortCol: {
      type: Number,
    },
  },
  data(){
    return {
      drawerOpen: false,
    }
  },
  methods: {
    toggleDrawer(id){
      this.drawerOpen = !this.drawerOpen;
      this.$emit('open-drawer',id);
      $('[data-toggle="tooltip"]').tooltip();
    },
    tdClass(id){
      return {
        sorting_1: this.sortCol === id,
        // 'd-none': this.sortCol === id && this.sortCol === 0
      }
    },
  },
  computed: {
    classObject(){
      return {
        even: this.index % 2 === 0,
        odd: this.index % 2 !== 0
      }
    }
  }
}
</script>
