<template>
  <form ref="orderForm" id="orderForm">
    <div class="alert alert-danger text-center" role="alert" v-if="errors">
      {{ errors }}
    </div>
    <fieldset>
      <legend>Influencer Details:</legend>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="influencer">Influencer</label>
          <Select2 v-model="orderSample.influencer_id" :options="influencersOptions" :settings="{ width: '100%', allowClear: true, placeholder: 'Select an influencer'}"  />
          <a href="#" @click.prevent="showInfluencerForm"><i class="fa fa-plus"></i> Add Influencer</a>
        </div>
        <div class="form-group col-md-6">
          <label for="address">Shipping Address</label>
          <select class="form-control" id="address" v-model="orderSample.address_id">
            <option value="" disabled selected>Choose Address</option>
            <option v-for="address in influencerAddresses" :value="address.id" :key="address.id">
              {{ address.name }} - {{address.street_line_1}} {{address.street_line_2}}, {{ address.zip }} {{ address.city }}
            </option>
          </select>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Product Details:</legend>
      <div class="form-group">
        <label for="shop">Shop</label>
        <Select2 v-model="orderSample.shop_id" :options="shopOptions" :settings="{ width: '100%', allowClear: true, placeholder: 'Select a shop'}" @change="getShopSkus($event)"  />
      </div>
      <div v-for="(item, index) in orderSample.order_items" :key="item.id">
        <div class="form-row">
          <div class="form-group col-md-8">
            <label for="sku">Product SKU</label>
            <Select2 name="items[][sku]" v-model="item.sku" :options="skusOptions" :settings="{ width: '100%', allowClear: true, placeholder: 'Select SKU'}" />
          </div>
          <div class="form-group col-md-1">
            <label for="quantity">Qty.</label>
            <input type="number" name="items[][quantity]" class="form-control" id="quantity" v-model="item.quantity">
          </div>
          <div class="form-group col-md-3">
            <label for="info">Extra Info</label>
            <textarea name="items[][necklace_name]" rows="3" class="form-control" id="info" v-model="item.necklace_name"></textarea>
          </div>
          <div class="form-group col-md-2 pt-lg" v-if="orderSample.order_items.length > 1">
            <a href="#" class="text-danger pull-right" @click.prevent="removeItem(index)"><i class="fa fa-times"></i> Remove</a>
          </div>
        </div>
      </div>
      <div class="form-group">
        <a href="#" @click.prevent="addNewItem"><i class="fa fa-plus"></i> Add Product</a>
      </div>
    </fieldset>
  </form>
</template>
<script>
import axios from 'axios';
import Select2 from 'v-select2-component';

export default {
  name: "Form",
  components: {Select2},
  data: function () {
    return {
      orderSample: {
        influencer_id: '',
        shop_id: '',
        order_items: []
      },
      shops: [],
      influencers: [],
      products: [],
      skus: [],
      orderItem: { sku: '', quantity: '', necklace_name: '' },
      errors: '',
      navigateModals: false,
      influencersOptions: [],
      shopOptions: [],
      skusOptions: []
    }
  },
  methods: {
    getShops(){
      axios.get('/shops.json').then(response => {
        this.shops = response.data;
        this.shopOptions = response.data.map(e => ({id: e.id, text:e.name}));
      })
    },
    influencerName(obj){
      return `${obj.first_name} ${obj.last_name}`
    },
    getInfluencers(){
      axios.get("/influencers.json?sortCol=0&sortDir=desc&limit=1000").then(response => {
        let data = response.data;
        this.influencers = data.data
        this.influencersOptions = data.data.map(e => ({id: e.id, text:`${e.first_name} ${e.last_name}`}));
      })

    },
    getShopSkus(shopId){
      this.skus = [];
      axios.get(`/shops/${shopId}/skus`).then(response => {
        this.skusOptions = response.data;
      });
      this.orderSample.order_items.map(o_i => o_i.sku = null)
      // $("select[name='items[][sku]']").val(null).trigger('change');
    },
    showInfluencerForm(){
      this.navigateModals = true;
      $("#add-sample-modal").modal('hide');
    },
    addNewItem() {
      this.orderSample.order_items.push(Object.assign({}, this.orderItem));
    },
    removeItem(index) {
      this.$delete(this.orderSample.order_items, index);
    },
    processForm(){
      const headers = {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
      this.navigateModals = false;
      const params = this.orderSample;

      axios.post('/orders/samples', params, { headers: headers}).then(response => {
        this.orderSample = {
          influencer_id: '',
          shop_id: '',
          order_items: []
        }
        this.errors = '';

        this.$emit('sample-added', response.data);
      }).catch(error => {
        this.errors = error.response.data.errors;
        $('#add-sample-modal').animate({ scrollTop: 0 }, 'slow');
      })
    }
  },
  computed: {
    influencerAddresses(){
      let influencerId = this.orderSample.influencer_id;
      if(influencerId === '') return [];
      return this.influencers.find(influencer => Number(influencer.id) === Number(influencerId)).addresses
    }
  },
  created() {
    this.getInfluencers();
    this.getShops();
    this.addNewItem();
    this.$parent.$on('form-submit', this.processForm);
  },
  mounted(){
    const vm = this;
    $('#add-sample-modal').on('hidden.bs.modal', function (event) {
      // do something...
      if(!vm.navigateModals) return;

      $('#nav-tab a:last-child').tab('show');
      $("#add-influencer-modal").modal('show');
    });
    $('#add-influencer-modal').on('hidden.bs.modal', function (event) {
      vm.getInfluencers();
      if(!vm.navigateModals) return;
      vm.navigateModals = false;
      vm.orderSample.influencer_id = '';

      $('#nav-tab a:first-child').tab('show');
      $("#add-sample-modal").modal('show');
    });

  }
}
</script>