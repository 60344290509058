<template>
  <tr :class="classObject">
    <td class="d-none" :class="tdClass(0)">{{ influencer.created_at }}</td>
    <td :class="tdClass(1)">{{ influencer.first_name }}</td>
    <td :class="tdClass(2)">{{ influencer.last_name}}</td>
    <td :class="tdClass(3)">{{ influencer.email }}</td>
    <td :class="tdClass(4)">{{ influencer.phone }}</td>
    <td :class="tdClass(5)">
      <a :href="linkTo('instagram',influencer.instagram)" target="_blank" v-if="influencer.instagram"><i class="fa fa-instagram"></i></a>
      <a :href="linkTo('snapchat', influencer.snapchat)" target="_blank" v-if="influencer.snapchat"><i class="fa fa-snapchat"></i></a>
      <a :href="linkTo('facebook', influencer.facebook)" target="_blank" v-if="influencer.facebook"><i class="fa fa-facebook"></i></a>
    </td>
    <td class="text-right">
      <a href="#" class="btn btn-secondary btn-sm" @click.prevent="editInfluencer(influencer)" v-if="!current_user_read_only">Edit</a>
      <a class="text-danger" data-confirm="Are you sure you want to delete this influencer? This action is irreversible." rel="nofollow" data-method="delete" :href="`/influencers/${influencer.id}`" v-if="influencer.deletable && !current_user_read_only">
        <i class='fa fa-trash'></i>
      </a>
      <a style="cursor: not-allowed;" v-if="!influencer.deletable && !current_user_read_only" title="Influencers with order samples cannot be deleted" data-toggle="tooltip">
        <i class='fa fa-trash'></i>
      </a>

<!--      <a href="#" class="btn btn-danger btn-sm">Delete</a>-->
    </td>
  </tr>
</template>
<script>
export default {
  name: "TableRow",
  props: {
    influencer: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true,
    },
    sortCol: {
      type: Number,
    },
    current_user_read_only: {
      type: Boolean
    }
  },
  methods: {
    tdClass(id){
      return {
        sorting_1: this.sortCol === id,
        // 'd-none': this.sortCol === id && this.sortCol === 0
      }
    },
    linkTo(service, username){
      if(service === "snapchat") return `https://www.snapchat.com/add/${username}`
      if(service === "instagram") return `https://www.instagram.com/${username}`
      if(service === "facebook") return `https://www.facebook.com/${username}`
    },
    editInfluencer(payload){
      this.$emit("edit-influencer",payload);
    }
  },
  computed: {
    classObject(){
      return {
        even: this.index % 2 === 0,
        odd: this.index % 2 !== 0
      }
    }
  }
}
</script>