<template>
  <form ref="dataExportForm" id="dataExportForm">
    <div class="alert alert-danger text-center" role="alert" v-if="errors">
      {{ errors }}
    </div>
    <fieldset>
      <legend>Product Details:</legend>
      <div v-for="(item, index) in export_data" :key="item.id">
        <div class="form-row">
          <div class="form-group col-md-8">
            <label for="sku">Product SKU</label>
            <select class="form-control" id="address" v-model="item.category">
              <option value="" disabled selected>Choose Category</option>
              <option v-for="category in dataExportCategories" :value="category.id" :key="category.id">
                {{ category.name }}
              </option>
            </select>
            <select class="form-control" id="address" v-model="item.sub_category">
              <option value="" disabled selected>Choose Sub Category</option>
              <option v-for="category in dataExportSubCategories" :value="category.id" :key="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2 pt-lg" v-if="export_data.length > 1">
            <a href="#" class="text-danger pull-right" @click.prevent="removeItem(index)"><i class="fa fa-times"></i> Remove</a>
          </div>
        </div>
      </div>
      <div class="form-group">
        <a href="#" @click.prevent="addNewItem"><i class="fa fa-plus"></i> Add Product</a>
      </div>
    </fieldset>
  </form>
</template>
<script>
import axios from 'axios';

export default {
  name: "DataExportForm",
  data: function () {
    return {
      export_data: []
    }
  },
  methods: {
    getShops(){
      axios.get('/shops.json').then(response => {
        this.shops = response.data;
        this.shopOptions = response.data.map(e => ({id: e.id, text:e.name}));
      })
    },
    influencerName(obj){
      return `${obj.first_name} ${obj.last_name}`
    },
    getInfluencers(){
      axios.get("/influencers.json?sortCol=0&sortDir=desc&limit=1000").then(response => {
        let data = response.data;
        this.influencers = data.data
        this.influencersOptions = data.data.map(e => ({id: e.id, text:`${e.first_name} ${e.last_name}`}));
      })

    },
    getSubCategories(category){
      this.skus = [];
      axios.get(`/shops/${shopId}/skus`).then(response => {
        this.skusOptions = response.data;
      });
      this.export_data.map(o_i => o_i.sku = null)
      // $("select[name='items[][sku]']").val(null).trigger('change');
    },
    addNewItem() {
      this.export_data.push({});
    },
    removeItem(index) {
      this.$delete(this.export_data, index);
    },
    processForm(){
      const headers = {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
      this.navigateModals = false;
      const params = this.orderSample;

      axios.post('/orders/samples', params, { headers: headers}).then(response => {
        this.orderSample = {
          influencer_id: '',
          shop_id: '',
          order_items: []
        }
        this.errors = '';

        this.$emit('sample-added', response.data);
      }).catch(error => {
        this.errors = error.response.data.errors;
        $('#add-sample-modal').animate({ scrollTop: 0 }, 'slow');
      })
    }
  },
  computed: {
    influencerAddresses(){
      let influencerId = this.orderSample.influencer_id;
      if(influencerId === '') return [];
      return this.influencers.find(influencer => Number(influencer.id) === Number(influencerId)).addresses
    },
    dataExportCategories(){
      return [{id: "invoices", name: "Invoices"}]
    },
    dataExportSubCategories(){
      return [
      {id: 'yc_invoice', name: 'YC Invoice data'},
      {id: 'xh_invoice', name: 'XH Invoice data'},
      {id: 'david_invoice', name: 'David Invoice and fees data'},
      {id: 'yc_vat', name: 'YC VAT and Tax data'},
      {id: 'xh_vat', name: 'XH VAT and Tax data'},
    ]}
  },
  created() {
    this.getInfluencers();
    this.getShops();
    this.addNewItem();
    this.$parent.$on('form-submit', this.processForm);
  },
  mounted(){
  }
}
</script>