<template>
  <div>
    <div class="row mb-lg">
      <div class="col-md-3 d-flex">
        <span class="span-center">Show</span>
        <select class="form-control form-control-sm small-select" v-model="perPage" @change="handleEntriesChange($event, fetchDataImports)">
          <option v-for="num in recordsPerPage" :key="num" :value="num">{{ num }}</option>
        </select>
        <span class="span-center">entries</span>
      </div>
      <div class="col-md-5"></div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-8">
           </div>
          <div class="col-md-4" v-if="!this.current_user_read_only">
            <button type="button" class="btn btn-primary pull-right" data-toggle="modal" data-target="#add-import-modal">
              New Import
            </button>
          </div>
        </div>
      </div>
    </div>

    <table class="table dtCustom" id="data-imports">
      <thead>
        <tr>
          <th scope="col" :class="{ sorting: col.sortable }" v-for="(col,index) in tableColumns" @click="sortBy(index, fetchDataImports)">{{ col.name }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="dataImports.length > 0">
          <template v-for="(dataImport, index) in dataImports">
            <data-import-table-row :index="index" :data-import="dataImport" :sort-col="sortCol" @open-drawer="openDrawer" :key="dataImport.id" />
            <data-import-info :data-import="dataImport" :key="dataImport.id" />
          </template>
        </template>
        <tr v-else>
          <td colspan="5" class="text-center p-4">No Records Found</td>
        </tr>
      </tbody>
    </table>
    <div class="row mt-4">
      <div class="col-3 p-3">
        <p>{{ positionText }}</p>
      </div>
      <div class="col-9">
        <v-pagination v-model="currentPage" :page-count="totalPages" :classes="bootstrapClasses"
                      :labels="paginationAnchorTexts"
                      v-on:input="handlePageChange($event, fetchDataImports)">

        </v-pagination>
      </div>
    </div>
    <modal modal-title="New Data Import" modal-id="add-import-modal" v-if="!this.current_user_read_only">
      <data-import-form/>
    </modal>
  </div>
</template>

<script>
import DataImportForm from '../../components/data/DataImportForm';
import DataImportInfo from '../../components/data/DataImportInfo';
import Modal from '../../components/Modal'
import vPagination from 'vue-plain-pagination'
import paginationMixin from "../../mixins/paginationMixin";
import currentUserMixin from "../../mixins/currentUserMixin";
import DataImportTableRow from "../../components/data/DataImportTableRow";
import axios from "axios";

export default {
  name: "DataImports",
  components: {
    DataImportForm,
    DataImportInfo,
    Modal,
    vPagination,
    DataImportTableRow
  },
  mixins: [ paginationMixin, currentUserMixin ],
  data: function () {
    return {
      tableColumns: [
        { name: "ID",sortable: false },
        { name: "Added on",sortable: false },
        { name: "Added by",sortable: false },
        { name: "From Date",sortable: false },
        { name: "To Date",sortable: false },
        { name: "Category",sortable: false },
        { name: "Sub Category",sortable: false },
        { name: "Status", sortable: false },
        { name: "Download", sortable: false },
      ],
      perPage: 50,
      sortCol: 1,
      tableId: 'table#data-imports',
      drawerStatus: { id: 0, open: false },
      dataImports: []
    }
  },
  methods: {
    openDrawer(id){
      document.getElementById(id).classList.toggle('hidden');
    },
    fetchDataImports(){
      const params = this.getRequestParams({
        searchTerm: this.searchTerm,
        page: this.currentPage,
        perPage: this.perPage,
        sortCol: this.sortCol,
        sortDir: this.sortDir,
      });

      axios.get("/data/imports.json", { params }).then(response => {
        let data = response.data;

        this.dataImports = data.data
        this.currentPage  = data.current_page;
        this.totalPages   = data.total_pages;
        this.totalEntries = data.total_entries;
      }).catch(error => {
        let response = error.response;
        alert(`Error Code: ${response.status} => ${response.statusText}`)
      });
    },
  },
  created() {
    this.fetchDataImports();
  },
}
</script>
