<template>
  <div>
    <div class="row mb-lg">
      <div class="col-md-3 d-flex">
        <span class="span-center">Show</span>
        <select class="form-control form-control-sm small-select" v-model="perPage" @change="handleEntriesChange($event, fetchInfluencers)">
          <option v-for="num in recordsPerPage" :key="num" :value="num">{{ num }}</option>
        </select>
        <span class="span-center">entries</span>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="Search..." v-model="searchTerm" v-on:input="performSearch(fetchInfluencers)" />
          </div>
          <div class="col-md-4" v-if="!this.current_user_read_only">
            <button type="button" class="btn btn-primary pull-right" @click="showModal({}, false)">
              Add Influencers
            </button>
          </div>
        </div>
      </div>
    </div>

    <table class="table dtCustom" id="influencers" style="width: 100%;">
      <thead>
      <tr>
        <th scope="col" :class="{ sorting: col.sortable, 'd-none': index === 0 }" v-for="(col,index) in tableColumns" @click="sortBy(index, fetchInfluencers)">{{ col.name }}</th>
      </tr>
      </thead>
      <tbody>
        <template v-if="influencers.length > 0">
          <influencer-table-row v-for="(influencer, index) in influencers"
                                :influencer="influencer" :index="index" :key="influencer.id" :sort-col="sortCol" :current_user_read_only="current_user_read_only"
                                @edit-influencer="showModal"/>
        </template>
        <tr v-else>
          <td colspan="7" class="text-center p-4">No Records Found</td>
        </tr>
      </tbody>
    </table>
    <div class="row mt-4">
      <div class="col-3 p-3">
        <p>{{ positionText }}</p>
      </div>
      <div class="col-9">
        <v-pagination v-model="currentPage" :page-count="totalPages" :classes="bootstrapClasses" :labels="paginationAnchorTexts" v-on:input="handlePageChange($event, fetchInfluencers)"></v-pagination>
      </div>
    </div>

    <modal :modal-title="modalTitle" :submit-btn-text="buttonText" modal-id="add-influencer-modal" v-if="!this.current_user_read_only">
      <influencer-form :populate-with="currentInfluencer" :is-editing="isEditing"
                       @influencer-added="addInfluencer" @influencer-updated="updateInfluencer" />
    </modal>
  </div>
</template>
<script>
import InfluencerForm from "../components/order_samples/InfluencerForm";
import paginationMixin from "../mixins/paginationMixin";
import currentUserMixin from "../mixins/currentUserMixin";
import vPagination from 'vue-plain-pagination'
import InfluencerTableRow from "../components/order_samples/InfluencerTableRow";
import Modal from '../components/Modal'
import axios from "axios";

export default {
  name: "Influencers",
  components: { InfluencerForm, Modal, InfluencerTableRow, vPagination },
  mixins: [ paginationMixin, currentUserMixin ],
  data: function(){
    return {
      tableColumns: [
        { name: "Created",sortable: true },
        { name: "First Name",sortable: true },
        { name: "Last Name",sortable: true },
        { name: "Email",sortable: true },
        { name: "Phone", sortable: true },
        { name: "Profiles", sortable: false },
        { name: "", sortable: false },
      ],
      tableId: 'table#influencers',
      currentInfluencer: {},
      influencers: [],
      isEditing: false
    }
  },
  methods: {
    fetchInfluencers(){
      const params = this.getRequestParams({
        searchTerm: this.searchTerm,
        page: this.currentPage,
        perPage: this.perPage,
        sortCol: this.sortCol,
        sortDir: this.sortDir,
      });

      axios.get("/influencers.json", { params }).then(response => {
        let data = response.data;

        this.influencers  = data.data
        this.currentPage  = data.current_page;
        this.totalPages   = data.total_pages;
        this.totalEntries = data.total_entries;
      }).catch(error => {
        let response = error.response;
        alert(`Error Code: ${response.status} => ${response.statusText}`)
      });
    },
    addInfluencer(influencer){
      this.influencers.unshift(influencer);
      $("#add-influencer-modal").modal('hide');
    },
    showModal(payload, editing = true){
      this.isEditing = editing;
      this.currentInfluencer = payload;
      $("#add-influencer-modal").modal('show');
    },
    updateInfluencer(payload){
      this.isEditing = false;
      this.influencers = this.influencers.map(influencer =>
          influencer.id === payload.id ? payload : influencer
      );
      $("#add-influencer-modal").modal('hide');
    }
  },
  computed: {
    modalTitle(){
      return this.isEditing ? "Edit Influencer Details" : "New Influencer Details"
    },
    buttonText(){
      return this.isEditing ? "Update" : "Submit"
    }
  },
  created() {
    this.fetchInfluencers();
  },
  mounted() {
    const vm = this;
    this.$nextTick(() => {
      $('#add-influencer-modal').on('hidden.bs.modal', function (e) {
        vm.isEditing = false;
        this.currentInfluencer = {}
      });
    })
  }
}
</script>