export default {
    data() {
        return {
            currentPage: 1,
            totalPages: 1,
            totalEntries: 0,
            searchTerm: '',
            timerId: '',
            perPage: 25,
            recordsPerPage: [10, 25, 50, 100],
            sortCol: 0,
            sortDir: "desc",
            bootstrapClasses: {
                ul: 'pagination float-right',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            paginationAnchorTexts: {
                first: 'First',
                prev: 'Previous',
                next: 'Next',
                last: 'Last'
            }
        };
    },
    methods: {
        getRequestParams({searchTerm = null, page= null,perPage = null, sortCol=this.sortCol, sortDir=this.sortDir}) {
            let params = {};
            if (searchTerm) {
                params['q'] = searchTerm;
            }
            if (page) {
                params['page'] = page;
            }
            if (perPage) {
                params['per_page'] = perPage;
            }
            params['sortCol'] = sortCol
            params['sortDir'] = sortDir
            return params;
        },
        highlightCol(idx) {
            let direction = this.sortDir;
            var tbl = document.querySelector(this.tableId);

            // // remove all sorting
            Array.from(tbl.querySelectorAll('th')).forEach(function(el) {
                el.classList.remove('sorting_asc','sorting_desc');
            });

            let classToRemove = direction === "asc" ? 'sorting_desc' : 'sorting_asc';
            tbl.getElementsByTagName('th')[idx].classList.remove(classToRemove);
            tbl.getElementsByTagName('th')[idx].classList.add(`sorting_${direction}`);
        },
        sortBy(idx, callbackFunc){
            let col = this.tableColumns[idx];
            if(!col.sortable) return;

            this.sortCol = idx
            this.sortDir = this.sortDir === "asc" ? "desc" : "asc";

            this.highlightCol(this.sortCol);
            callbackFunc();
        },
        handlePageChange(value, callbackFunc) {
            this.currentPage = value;
            callbackFunc();
        },
        handleEntriesChange(event, callbackFunc) {
            this.perPage = event.target.value;
            this.currentPage = 1;
            callbackFunc();
        },
        performSearch(callbackFunc) {
            clearInterval(this.timerId);
            this.currentPage = 1;
            this.timerId = setTimeout(callbackFunc, 400);
        },
    },
    computed: {
        positionText() {
            var endIndex = this.currentPage * this.perPage,
                startIndex = this.totalEntries === 0 ? 0 : (this.currentPage - 1) * this.perPage + 1;

            return 'Showing ' + startIndex + ' to ' + (endIndex > this.totalEntries ? this.totalEntries : endIndex) + ' of ' + this.totalEntries;
        }
    }
}