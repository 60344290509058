<template>
  <form action="/invoices_exports" method="post" enctype="multipart/form-data" ref="invoiceExportForm" id="invoiceExportForm">
    <div class="alert alert-danger" role="alert" v-if="errors.length">
      <ul>
        <li v-for="error in errors">{{ error }}</li>
      </ul>
    </div>
    <fieldset v-for="(item, index) in invoiceExport.data">
      <legend>Invoice Export:</legend>
      <div>
        <div class="form-group col-md-8">
          <label>From:</label>
          <input type="date" name="data[][from_date]" required>
        </div>
        <div class="form-group col-md-8">
          <label>To:</label>
          <input type="date" name="data[][to_date]" required>
        </div>
        <div class="form-group col-md-8">
          <label>Category:</label>
          <select name="data[][sub_category]" class="form-control" v-model="item.sub_category" required>
            <option value="" disabled selected>Choose Sub Category</option>
            <option v-for="category in invoiceExportSubCategories" :value="category.id" :key="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
      </div>
    </fieldset>
  </form>
</template>
<script>
import axios from "axios";

export default {
  name: "InvoiceExportForm",
  data: function() {
    return {
      invoiceExport: {
        data: [{}]
      },
      data: {},
      errors: [],
      invoiceExportSubCategories: [
        {id: 'sweetplaid_fr', name: 'Sweetplaid FR Invoices'},
        {id: 'sweetplaid_be', name: 'Sweetplaid BE Invoices'},
      ],
    }
  },
  methods: {
    resetForm(){
      this.invoiceExport = {data: [{}]}
    },
    processForm(){
      $("#invoiceExportForm").submit();
      console.log("Submitted! :>")
    },
  },
  watch: {
    populateWith: {
      deep: true,
      handler(){
        this.resetForm();
        this.errors = [];
      }
    }
  },
  created() {
    this.$parent.$on('form-submit', this.processForm);
  }
}
</script>
